<template>
    <div>
        <banner banner-name="标签"
                :background-img="$store.getters.getBackground('tags')"/>
        <v-card class="blog-container an-blog-InUp">
            <div class="tag-title">
                <span class="mr-1">标签</span> <span class="ml-1">-</span> <span>{{tagList.length}}</span>
            </div>
            <div class="tag-cloud">
                <router-link :to="'/tags/'+tag.id" tag="a"
                             :style="{ 'font-size': Math.floor(Math.random() * 10) + 18 + 'px' }"
                             v-for="tag in tagList"
                             :key="tag.id"
                             class="pr-md-3 pl-md-3 pl-2 pr-2 animate__animated animate__flipInY"
                >{{tag.tag_name}}
                </router-link>
            </div>
        </v-card>
    </div>
</template>

<script>
  import Banner from "@/components/banner/Banner"
  import {getArticlesTagsApi} from "@/network/interface"

  export default {
    name: "Tag",
    data() {
      return {
        tagList: []
      }
    },
    created() {
      this.getArticlesTags()
    },
    methods: {
      getArticlesTags() {
        getArticlesTagsApi({}).then(resp => {
          this.tagList = resp.data
        })
      }
    },
    components: {
      Banner
    }
  }
</script>

<style scoped>


    .blog-container {
        padding: 50px 40px;
    }

    .tag-title {
        margin-bottom: 1rem;
        text-align: center;
        font-size: 2rem;
        line-height: 2;
    }

    .tag-cloud a {
        display: inline-block;
        transition: all 0.3s;
    }

    .tag-cloud a:hover {
        color: #03a9f4 !important;
        transform: scale(1.1);
    }


    .theme--dark .tag-cloud a {
        color: #eeeeee !important;
    }

</style>
